<template>
  <div>
    <div class="scroll">
      <img :src="ossurl + webSite" />
    </div>
    <bottomtag class="bottomtag"></bottomtag>
  </div>
</template>
<script>
import { selectUserCard } from '@/api/abt/customerOperation/visitingcard'
import { DoRecord } from '@/api/abt/customerOperation/common'
import bottomtag from '../component/bottomtag'
import { getStorage } from '@/lib/util'
import { wechatshare } from '@/lib/wechat_share'
import config from '@/config'
import initWebSocket from '@/mixins/websock'
export default {
  mixins: [initWebSocket],
  components: {
    bottomtag
  },
  data () {
    return {
      user: '',
      ossurl: '',
      empno: '',
      rytype: '',
      suserid: '',
      height: '',
      webSite: '',
      gwname: '',
      gwpic: {}
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    this.ossurl = getStorage('ossurl', '')
    this.gwname = getStorage('comname', '')
    this.gwpic = getStorage('sys_info', {})
    this.empno = this.$route.query.empno
      ? this.$route.query.empno
      : this.user.empno
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid
    this.height = document.body.clientHeight
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 50}px`
  },
  destroyed: function () {
    this.websocketclose() // 页面销毁时关闭websocket连接
  },
  methods: {
    getData () {
      let data = {
        empno: this.empno,
        flag: '1',
        type: this.rytype
      }
      selectUserCard(data).then(res => {
        let Data = res.data.data
        this.webSite = Data.webSite
        this.behaviorRecord()
        let shareurl = `${config.redirect_uri}/website?empno=${this.empno}&rytype=${this.rytype}&suserid=${this.user.userid}&type=12`
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '2',
          btagcode: '12',
          rytype: this.rytype
        }
        if (this.$route.query.sno) {
          fxstr.sno = this.$route.query.sno
        }
        let path = ''
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          // ios
          if (this.$route.query.frompage == 'source') {
            path = config.redirect_uri + '/source'
          } else {
            path = config.redirect_uri
          }
        } else {
          // android
          path = window.location.href
        }
        wechatshare(
          this.gwname,
          `感谢您对我官网的信赖与支持！`,
          this.gwpic.sharelogo,
          shareurl,
          path,
          fxstr
        )
      })
    },
    // 行为记录
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '12',
        rytype: this.rytype
      }
      if (this.$route.query.sno) {
        data.sno = this.$route.query.sno
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll {
  overflow-y: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.bottomtag {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
}
</style>
